/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  NavigationItemBase,
  NavigationItemLevel1,
  NavigationItemLevel2,
  NavigationItemLevel3,
  NavigationItemLevel4,
  PrimaryNavigation
} from "../types/Navigation";

export const shouldIncludeItemByScope = (item: NavigationItemBase, currentScopes: string[]) => {
  return item.enabled === undefined
      || (typeof item.enabled === "function" ? item.enabled(currentScopes) : currentScopes.includes(item.enabled))
};

export const filterNavigationItems =
    <T extends NavigationItemLevel1 | NavigationItemLevel2 | NavigationItemLevel3 | NavigationItemLevel4>(scopes: string[], navItems: T[]): T[] => {
  return navItems
      .filter(item => shouldIncludeItemByScope(item, scopes))
      .map(item => {
        return [
          item,
          item.children !== undefined
              ? filterNavigationItems<NavigationItemLevel1 | NavigationItemLevel2 | NavigationItemLevel3 | NavigationItemLevel4>
                (scopes, item.children)
              : undefined
        ] as [T, typeof item.children];
      })
      .filter(([item, childrenFiltered]) => item.href !== undefined ||
          item.onClick !== undefined ||
          (childrenFiltered !== undefined && childrenFiltered.length !== 0))
      .map(([item, childrenFiltered]) => {
        return ({
          ...item,
          children: childrenFiltered
        });
      });
};

/**
 * process and filter primary navigation
 * in level 2, some sections can be multi-column, where the not-firsts columns have no name. but if because of
 * permissions all entries of the first columns are filtered away, the headline is missing for the other columns.
 * so we have to add the name back.
 *
 * caveat of the current approach: can't define L2 name as always intentionally empty or have same headline next to
 * another using same l10n-key
 * @param scopes actual permissions of user
 * @param primaryNavigationArg primary navigation object
 */
export const processAndFilterPrimaryNavigation = (scopes: string[], primaryNavigationArg: PrimaryNavigation) => {
  const cloned: PrimaryNavigation = {...primaryNavigationArg};
  // clone to incl. ItemLevel2 itself
  cloned.items = cloned.items.map((l1) => {
    return {
      ...l1, children: l1.children?.map((l2) => {
        return {...l2};
      })
    } as NavigationItemLevel1;
  });

  // pre-process L2 name: add where empty
  for (const l1 of cloned.items) {
    if (!l1.children) {
      continue;
    }
    //loop forwards
    for (let i = 0; i < l1.children.length; i++) {
      if (i > 0 && (l1.children[i].key == null || l1.children[i].key === "")) {
        l1.children[i].key = l1.children[i - 1].key;
      }
    }
  }

  const filtered: PrimaryNavigation = {items: filterNavigationItems(scopes, cloned.items)};

  // delete L2 name if same as previous
  for (const l1 of filtered.items) {
    if (!l1.children) {
      continue;
    }
    // loop backwards
    for (let i = l1.children.length - 1; i >= 0; i--) {
      if (i > 0 && l1.children[i].key === l1.children[i - 1].key) {
        l1.children[i].key = "";
      }
    }
  }
  return filtered;
};