/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { PrimaryNavigation, TertiaryNavigation } from "../../../types/Navigation";
import { DHLFrameHeader } from "../DHLFrameHeader/DHLFrameHeader";
import { reaction } from "mobx";
import { useTranslation } from "react-i18next";
import { filterNavItems, processAndFilterPrimaryNavigation, isEnabled } from "../../../utils/filterNavigationItems";
import { Branding } from "../../../types/Branding";
import { AuthenticationManagerContext, logger } from "@gkuis/gkp-base-widgets";
import moment from "moment";

export type RootProps = {
  mode: "internal" | "external"
  authenticationManager: AuthenticationManager,
  primaryNavigation: PrimaryNavigation,
  tertiaryNavigation: TertiaryNavigation,
  branding: Branding
}

export const Root = observer(({
                                mode,
                                authenticationManager,
                                primaryNavigation,
                                tertiaryNavigation,
                                branding
                              }: RootProps) => {
  const {i18n} = useTranslation();
  i18n.setDefaultNamespace(mode);

  useEffect(() => reaction(
      () => authenticationManager.language,
      (language) => {
        void i18n.changeLanguage(language);
        moment.locale(authenticationManager.language.startsWith("en") ? "en-gb" : "de");
      },
      {fireImmediately: true}
  ), [authenticationManager, i18n]);

  const [filteredPrimaryNavigation, setFilteredPrimaryNavigation] = useState<PrimaryNavigation>({items: []});
  const [filteredTertiaryNavigation, setFilteredTertiaryNavigation] = useState<TertiaryNavigation>({});
  useEffect(() => {
    const listener = async () => {
      let scopes: string[];
      try {
        scopes = await authenticationManager.getScopes();
      } catch (error) {
        logger.warn("Error getting scopes",error);
        scopes = [];
      }

      setFilteredPrimaryNavigation(await processAndFilterPrimaryNavigation(primaryNavigation, scopes));

      const filteredTertNav: TertiaryNavigation = {};
      if (tertiaryNavigation.myMessages !== undefined && await isEnabled(tertiaryNavigation.myMessages, scopes)) {
        filteredTertNav.myMessages = tertiaryNavigation.myMessages;
      }
      if (tertiaryNavigation.myAccount !== undefined && await isEnabled(tertiaryNavigation.myAccount, scopes)) {
        const filteredMyAccountItems = await filterNavItems(tertiaryNavigation.myAccount.items, scopes);
        if (filteredMyAccountItems.length > 0) {
          filteredTertNav.myAccount = {
            ...tertiaryNavigation.myAccount,
            items: filteredMyAccountItems
          };
        }
      }
      setFilteredTertiaryNavigation(filteredTertNav);
    };
    void listener();
    const onAuthRefreshSuccessListenerDisposer = authenticationManager.addEventListener("onAuthRefreshSuccess", listener);
    const onAuthSuccessListenerDisposer = authenticationManager.addEventListener("onAuthSuccess", listener);
    return () => {
      onAuthRefreshSuccessListenerDisposer();
      onAuthSuccessListenerDisposer();
    };
  }, [authenticationManager, setFilteredPrimaryNavigation, setFilteredTertiaryNavigation, primaryNavigation, tertiaryNavigation]);

  return (
      <AuthenticationManagerContext.Provider value={authenticationManager}>
        <BrowserRouter>
          <DHLFrameHeader
              mode={mode}
              primaryNavigation={filteredPrimaryNavigation}
              tertiaryNavigation={filteredTertiaryNavigation}
              branding={branding}
          />
        </BrowserRouter>
      </AuthenticationManagerContext.Provider>
  );
});
