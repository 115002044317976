/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { PrimaryNavigation, TertiaryNavigation } from "../../../types/Navigation";
import { DHLFrameHeader } from "../DHLFrameHeader/DHLFrameHeader";
import { reaction } from "mobx";
import { useTranslation } from "react-i18next";
import { filterNavigationItems, processAndFilterPrimaryNavigation, shouldIncludeItemByScope } from "../../../utils/filterNavigationItems";
import { Branding } from "../../../types/Branding";
import { AuthenticationManagerContext } from "@gkuis/gkp-base-widgets";
import moment from "moment";

export type RootProps = {
  mode: "internal" | "external"
  authenticationManager: AuthenticationManager,
  primaryNavigation: PrimaryNavigation,
  tertiaryNavigation: TertiaryNavigation,
  branding: Branding
}

export const Root = observer(({
                                mode,
                                authenticationManager,
                                primaryNavigation,
                                tertiaryNavigation,
                                branding
                              }: RootProps) => {
  const {i18n} = useTranslation();
  i18n.setDefaultNamespace(mode);

  const [scopes, setScopes] = useState<string[]>([]);
  useEffect(() => reaction(
      () => authenticationManager.authenticatedSubject,
      () => authenticationManager.getScopes().then(setScopes).catch(() => setScopes([])),
      {fireImmediately: true}
  ), [authenticationManager, setScopes]);

  useEffect(() => reaction(
      () => authenticationManager.language,
      (language) => {
        i18n.changeLanguage(language);
        moment.locale(authenticationManager.language.startsWith("en") ? "en-gb" : "de");
      },
      {fireImmediately: true}
  ), [authenticationManager, i18n]);

  const filteredPrimaryNavigation = processAndFilterPrimaryNavigation(scopes, primaryNavigation);

  const {myMessages, myAccount} = tertiaryNavigation;
  const filteredTertiaryNavigation: TertiaryNavigation = {
    myMessages: (myMessages && shouldIncludeItemByScope(myMessages, scopes) && myMessages) || undefined,
    myAccount: (myAccount
        && shouldIncludeItemByScope(myAccount, scopes)
        && filterNavigationItems(scopes, myAccount.items).length > 0 && {
          ...myAccount,
          items: filterNavigationItems(scopes, myAccount.items)
        }) || undefined,
  };

  return (
      <AuthenticationManagerContext.Provider value={authenticationManager}>
        <BrowserRouter>
          <DHLFrameHeader
              mode={mode}
              primaryNavigation={filteredPrimaryNavigation}
              tertiaryNavigation={filteredTertiaryNavigation}
              branding={branding}
          />
        </BrowserRouter>
      </AuthenticationManagerContext.Provider>
  );
});
